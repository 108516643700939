import React from "react"
import LayoutComponent from "../components/LayoutComponent/LayoutComponent"
import MenuComponent from "../components/MenuComponent/MenuComponent"
import IntroImage from "../assets/image/intro.png"
import "../styles/global.scss"
import SEO from "../components/seo"
import SponsorComponent from "../components/SponsorComponent/SponsorComponent"
import ContactComponent from "../components/ContactComponent/ContactComponent"
import NewsComponent from "../components/NewsComponent/NewsComponent"
import VideoComponent from "../components/VideoComponent/VideoComponent"

const Home = () => {
  return (
    <LayoutComponent isEnglish languageSwitchSubpage="">
      <SEO title="Home" />
      <MenuComponent
        selected={1}
        isEnglish
        submenu={[
          { title: "Bouts and Events", url: "#bouts" },
          { title: "We Love our Sponsors", url: "#sponsors" },
          { title: "Contact", url: "#contact" },
        ]}
      />
      <img alt="" src={IntroImage} className="intro-image" />
      <NewsComponent isEnglish />
      <SponsorComponent />
      <ContactComponent isEnglish />
    </LayoutComponent>
  )
}

export default Home
